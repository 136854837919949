import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  useTheme,
} from "@mui/material";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  useGetAllRegisterTypesQuery,
  useGetAllRolesQuery,
  useCreateUserMutation,
  usePostOTPMutation,
  useVerifyOTPMutation,
  useGetPlatformQuery,
} from "../../services/api";
import { useNavigate } from "react-router-dom";
import black_bg from "../../Assests/auth-bg.jpg";
import smallLogo from "../../Assests/hp_icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import moment from "moment";
import dayjs from "dayjs";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
const validationSchema = yup.object({
  register: yup.string().required("Please select register type"),
  name: yup
    .string()
    .required("Please enter your name")
    .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)?$/, "Enter a valid name"),
  gender: yup.string().required("Please select your gender"),
  number: yup
    .string("Please enter valid number")
    .matches(/^[0-9]{10}$/, "Enter a valid 10-digit phone number")
    .required("Please enter your mobile number"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  Cpassword: yup
    .string("Enter your password")
    .required("Confirm Password Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
    // otp: yup.string()
    // .when('showOTPField', {
    //   is: true,
    //   then: yup.string().required('OTP field is required')
    // }),
  // startup_policy: yup.string()
  //   .required('Startup Policy field is required'),
  dpiit: yup.string()
    .required('DPIIT field is required'),
   dpiit_number: yup.lazy(value => {
    if (value === "yes") {
      return yup.string().required('DPIIT Number field is required');
    }
    return yup.string().nullable();
  }),
  dpiit_start_date: yup.lazy(value => {
    if (value === "yes") {
      return yup.date().required('DPIIT Start Date field is required');
    }
    return yup.string().nullable();
  }),
});





const Register = () => {
  const [userRoleID, setUserRoleID] = useState([]);
  const [dob, setDob] = useState(new Date());
  const [captcha, setCaptcha] = useState("");
  const { data, error, isLoading } = useGetAllRegisterTypesQuery();
  const {
    data: roleData,
    error: roleError,
    isLoading: roleIsLoadng,
  } = useGetAllRolesQuery("user");
  const [showOTPField, setShowOTPField] = useState(false);
  

  useEffect(() => {
    if (!roleIsLoadng && !roleError)
      setUserRoleID([roleData?.data[0]._id]);
  }, [roleData, roleError, roleIsLoadng]);

  const formik = useFormik({
    initialValues: {
      register: "",
      name: "",
      gender: "",
      category: "",
      number: "",
      email: "",
      password: "",
      Cpassword: "",
      otp: "",
      startup_policy: "",
      dpiit: "no",
      dpiit_number: "",
      dpiit_start_date: new Date(),

      incubated_registered  : "no",
      // dpiit_number: "",
      // dpiit_start_date: new Date(),

      otp_respose: null,
      refer_id:""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {  
      
      if (!validateCaptcha(captcha) == true) {
        toast.error("Captcha did not match", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadCaptchaEnginge(6);
        return;
      }
      const getData = {
        email: values.email,
        name: values.name,
        gender: values.gender,
        register: values.register,
        mobile: values.number,
        password: values.password,
        roles: userRoleID,
        dob: moment(dob).format("YYYY-MM-DD"),
        category: values.category,
        // otp: "",
        startup_policy: localStorage.getItem("policy"),
        have_dpiit: values.dpiit,
        dpiit_number:values.dpiit_number,
        dpiit_start_date: values.dpiit_start_date,
        refer_id:localStorage.getItem('policy')
      };
  
      const {
        data: userData,
        error: isCreateError,
        isLoading: isUserLoading,
      } = await createUser(getData);
      if (userData && userData?.success === true) {
        const time = 2000;
        toast.success("User Created successfully", {
          position: "top-center",
          autoClose: time,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          navigate("/sign-in");
        }, time + 1000);
      }
      if (isCreateError?.data && !isUserLoading) {
        toast.error(isCreateError.data.message ?? "Something went wrong", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
  });

  const [createUser] = useCreateUserMutation();
  const [postMob] = usePostOTPMutation();
  const [postVerifyOtp] = useVerifyOTPMutation();
  const theme=useTheme()

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  useEffect(() => {
    loadCaptchaEnginge(6, "grey");
  }, []);
  const handleGetOTP = async () => {
    if (formik.values.number && !formik.values.otp) {
      // Send mobile number to backend to request OTP
      const response = await postMob({ mobile: formik.values.number });
      if (response?.data?.success) {
        // OTP sent successfully, show OTP input field
        setShowOTPField(true);
        // Optionally, you can display a message to the user indicating that the OTP has been sent
        toast.success(response?.data?.data, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Handle error if OTP sending failed
        toast.error("Failed to send OTP. Please try again.", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else if (formik.values.otp && formik.values.number) {
      // Verify OTP using the provided OTP and mobile number
      const response = await postVerifyOtp({
        otp: formik.values.otp,
        mobile: formik.values.number,
      });
      formik.setFieldValue("otp_respose", response?.data?.success);
      if(response?.data?.success){

        toast.success(response?.data?.data, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else{
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // Handle the response accordingly
    } else {
      // toast.error(response?.data?.data, {
      //   position: "top-center",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      // This block handles the scenario where neither the mobile number nor the OTP is provided
      console.error("Mobile number and OTP are required");
    }
  };
  return (
    <Box className="screen-app-container">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          background: `url(${black_bg}) center center no-repeat`, // Center the background image
          backgroundSize: "cover",
          width: "100%",
          justifyContent: "space-around",
          height:'100%',
          overflowY:'auto',
          backgroundColor: (theme) =>
            theme.palette.background.default,
          color: (theme) => theme.palette.text.primary
        }}
      >
        <ToastContainer />
        <Container component="main" 
        // className="register-page d-flex h-100 justify-content-center align-items-center"
         maxWidth="lg">
          <Paper
            sx={{
              padding: "1em 1em",
              marginTop: "3em",
              marginBottom: "1em",

            }}
            elevation={5}
          >
             
            {/* <Box
              component="form"
              // onSubmit={formik.handleSubmit}
              noValidate
              // sx={{ mt: 1 }}
            > */}
               <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "10%",
                flexDirection: "column",
                gap: "2px",
                marginBottom: "1rem",
              }}
            >
              <img src={smallLogo} alt="" style={{ width: "80px" }} />
              <Grid item md={6} lg={6} sm={6}>
                <Typography variant="h5" align="center" fontWeight="600">
                 Sign In
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} sm={6}>
                <CustomizedProgressBars val={50} />
              </Grid>
            </Grid>
              <Grid container spacing={2}>
            
                {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box
                    className="sec-align-center"
                    style={{ textAlign: "center" }}
                  >
                    <img src={smallLogo} alt="" style={{ width: "80px" }} />
                    <Typography component="h1" variant="h5">
                      Sign Up
                    </Typography>
                  </Box>
                </Grid> */}

                {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="startup_policy">
                      Startup Scheme/ Policy
                    </InputLabel>
                    <Select
                      margin="normal"
                      required
                      fullWidth
                      id="startup_policy"
                      label="Startup Scheme/ Policy"
                      name="startup_policy"
                      autoFocus
                      select
                      value={formik.values.startup_policy}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.startup_policy &&
                        Boolean(formik.errors.startup_policy)
                      }
                      helperText={
                        formik.touched.startup_policy &&
                        formik.errors.startup_policy
                      }
                      autoComplete="off"
                      // onChange={(e) => handleChange(e)}
                    >
                      {platformList?.data?.map((item) => (
                        <MenuItem value={item._id} key={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                {/* Register Type */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="register-type">
                      Register Type
                    </InputLabel>
                    <Select
                      margin="normal"
                      required
                      fullWidth
                      id="register-type"
                      label="Register Type"
                      placeholder="Register Type"
                      name="register"
                      autoFocus
                      select
                      value={formik.values.register}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.register &&
                        Boolean(formik.errors.register)
                      }
                      helperText={
                        formik.touched.register && formik.errors.register
                      }
                      autoComplete="Register type"
                      // onChange={(e) => handleChange(e)}
                    >
                      {!isLoading &&
                        data?.data?.map((item) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={-2}>
               
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    placeholder="Name"
                    autoComplete="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.name && Boolean(formik.errors.name)
                    }
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>

                {/* ROLES */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormControl >
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Have you DPIIT recognised Startup
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="dpiit"
                      value={formik?.values?.dpiit}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value={"yes"}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={"no"}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
               

                {formik?.values?.dpiit == "yes" && (
                  <>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="dpiit_number"
                        label="DPIIT Number"
                        name="dpiit_number"
                        autoComplete="dpiit_number"
                        value={formik.values.dpiit_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.dpiit_number &&
                          Boolean(formik.errors.dpiit_number)
                        }
                        helperText={
                          formik.touched.dpiit_number &&
                          formik.errors.dpiit_number
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <DatePicker
                        disableFuture
                        sx={{
                          width: "100%",
                          marginTop: "1em",
                          marginBottom: "0.5em",
                        }}
                        value={dayjs(moment(dob).format("YYYY-MM-DD"))}
                        selected={formik.values.dpiit_start_date}
                        name="dpiit_start_date"
                        label="DPIIT Started Date"
                        margin="normal"
                        onChange={(value) =>
                          formik.setFieldValue("dpiit_start_date", value["$d"])
                        }
                      />
                    </Grid>
                  </>
                )}
                 <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormControl >
                    <FormLabel id="incubated_registered">
                    Have you Incubated from the State Registered Incubation centre
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="incubated_registered"
                      name="incubated_registered"
                      value={formik?.values?.incubated_registered}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value={"yes"}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={"no"}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {formik?.values?.incubated_registered == "yes" ?navigate("/sign-in"):null}

                {/* Date of birth */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <DatePicker
                    disableFuture
                    sx={{
                      width: "100%",
                      marginBottom: "0.5em",
                    }}
                    value={dayjs(moment(dob).format("YYYY-MM-DD"))}
                    selected={dob}
                    name="dob"
                    label="Date of birth"
                    margin="normal"
                    onChange={(value) => setDob(value["$d"])}
                  />
                </Grid>

                {/* Gender */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="gender"
                    label="Gender"
                    name="gender"
                    select
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    helperText={formik.touched.gender && formik.errors.gender}
                    // onChange={(e) => handleChange(e)}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="famale">Female</MenuItem>
                    <MenuItem value="transgender">Transgender</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="category">Select Category</InputLabel>
                    <Select
                      margin="normal"
                      required
                      fullWidth
                      id="category"
                      label="Select Category"
                      placeholder="Select Category"
                      name="category"
                      autoFocus
                      select
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      helperText={
                        formik.touched.category && formik.errors.category
                      }
                      autoComplete="Register type"
                      // onChange={(e) => handleChange(e)}
                    >
                      {["General", "SC", "ST", "OBC", "Others"].map(
                        (item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>


                {/* Email */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    autoComplete="email"
                    // onChange={(e) => handleChange(e)}
                  />
                </Grid>
                                {/* Mobile Number */}
                 <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="number"
                    label="Mobile Number"
                    name="number"
                    autoComplete="number"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.number && Boolean(formik.errors.number)
                    }
                    helperText={formik.touched.number && formik.errors.number}
                  />
                                    {(formik.values.otp || formik.values.number) && (
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ mt: 1, mb: 1 }}
                      onClick={handleGetOTP}
                    >
                      {showOTPField ? "Submit OTP" : "Get OTP"}
                    </Button>
                  )}
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  {showOTPField && (
                    <TextField
                      required
                      fullWidth
                      name="otp"
                      label="OTP"
                      type="text"
                      id="otp"
                      autoComplete="otp"
                      value={formik.values.otp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.otp && Boolean(formik.errors.otp)}
                      helperText={formik.touched.otp && formik.errors.otp}
                    />
                  )}
                </Grid>

                {/* Password */}
                {formik?.values?.otp_respose ? (
                  <>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        autoComplete="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="Cpassword"
                        label="Confirm Password"
                        type="password"
                        id="confirm-password"
                        autoComplete="confirm-password"
                        value={formik.values.Cpassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.Cpassword &&
                          Boolean(formik.errors.Cpassword)
                        }
                        helperText={
                          formik.touched.Cpassword && formik.errors.Cpassword
                        }
                        // onChange={(e) => handleChange(e)}
                      />
                    </Grid></> ) : <></>}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Box className="captcha-continer">
                        <Box className="input-block">
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="captcha"
                            type="text"
                            id="captcha"
                            placeholder="Enter Verification code"
                            onChange={(e) => setCaptcha(e.target.value)}
                          />
                        </Box>
                        <LoadCanvasTemplate
                          reloadText="Get a new code"
                          reloadColor="white"
                        />
                      </Box>
                    </Grid>
                   
                 
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Button
                  // type="submit"
                  disabled={!formik.values.otp_respose}
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default form submission behavior
                    formik.handleSubmit(); // Manually trigger form submission
                  }}
                >
                  Submit
                </Button>
                </Grid>
                {/* <Grid container>
                                    <Grid item>
                                        <Link to="/sign-in" variant="body2">
                                            {"Already have an account? Sign in"}
                                        </Link>
                                    </Grid>
                                </Grid> */}
              </Grid>
            {/* </Box> */}
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default Register;
