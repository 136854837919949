import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid, Box, Container } from "@mui/material";
import CustomizedProgressBars from "./../CustomProgress/ProgressBar";
import { useTranslation } from "react-i18next";
import videoImg from "../../Assests/startup.png";
import "./style.scss";

const IframeSection = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: "30px 0", // Equal padding
        justifyContent: "space-around",
        // backgroundColor: "#ffffff",
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ padding: 2 }}>
              {/* <Typography
                component="h1"
                sx={{ fontWeight: 700, fontSize: "x-large" }}
              >
                {t("start_up_title")}
              </Typography>
              <CustomizedProgressBars val={50} /> */}
              <Grid textAlign={"center"} sx={{ marginBottom: 3 }} item xs={12}>
            <Typography
              component="h1"
              ml={2}
              sx={{ fontWeight: 700, fontSize: "x-large", color: (theme) => theme.palette.text.primary }}
            >
              {t("start_up_title")}
            </Typography>
            <CustomizedProgressBars className={"section_sep"} val={50} />
          </Grid>
              <Typography className="subheading-blue" component="h2">
                We will always help you to grow your Entrepreneurial Skill
              </Typography>
              <Typography className="para-margin" variant="p" component="p">
                Startup/New Industries means any entity, Proprietor, Limited
                Company, registered partnership firm under Indian Partnership
                Act 1932, or Limited Liability Partnership, proposing to set up
                an enterprise in micro or small-scale category in services
                sector relating to the focus areas defined in the Scheme and in
                the manufacturing sector in the state after 31.3.2016.
              </Typography>
              <Typography className="para-margin" variant="p" component="p">
                Innovation Project is the process of introducing new or making
                changes with updated technology, large and small radical and
                incremental, to products, processes, and services that result in
                the introduction of some new and innovative products.
              </Typography>
              <Typography className="para-margin" variant="p" component="p">
                In order to turn the educated youth from job seekers to job
                creator and in order to give support to the ‘Startup’ and
                ‘innovative projects’ in the state and to provide skills to the
                youth and potential investors to develop entrepreneurship “Chief
                Minister’s Startup/Innovation Projects/New Industries Scheme”
                has been formulated. This initiative envisages various
                incentives for startups so as to enable the entrepreneur’s
                success in their ventures. It also makes provision of creation
                of incubation Centers in the host institutions in the State in
                order to build capacities, develop networking, establish
                necessary infrastructure and generate awareness. The basic
                objective of this scheme is creation of self-employment and
                employment generation, upgrading the skills of entrepreneurs and
                to provide them support to set up their units under professional
                guidance, promotion of innovation in focus areas, setting up of
                incubation centers, creating working space for startups and
                innovative projects, providing incentives, etc. This Scheme also
                aims at helping and hand-holding entrepreneurs to select viable
                projects in the potential areas in manufacturing and service
                sectors and train them to set up startups and subsequently
                manage and run their enterprises professionally.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Box component="img" src={videoImg} sx={{ maxWidth: "100%" }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default IframeSection;
