import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Paper,
  TableBody,
  TableRow,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  api,
  useCreateApplicantMutation,
  useSingleApplicantQuery,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const DocumentUploaded = ({ steps, active, setActive }) => {
  const _id = localStorage.getItem("application_id");
  const [loading, setLoading] = useState(false);
  const [newApplicant] = useCreateApplicantMutation();
  const [getAllApplicant] = api.endpoints.singleApplicant.useLazyQuery();
  const { data: singleData } = useSingleApplicantQuery(_id);
  const navigate = useNavigate();
  const { id } = useParams();

  const validationSchema = Yup.object().shape({
    rows: Yup?.array().of(
      Yup?.object().shape({
        document: Yup.string().required(),
        name: Yup.string().when("key", {
          is: (val) =>
            _id
              ? null
              : val === "doc_aadhaar" || val === "pitch_deck" || val === "pic",
          then: () => Yup.string().required("Name is required"),
          otherwise: () => Yup.string().nullable(),
        }),
        file: Yup.mixed().when("key", {
          is: (val) =>
            val === "doc_aadhaar" || val === "pitch_deck" || val === "pic",
          then: () => Yup.mixed().required("File is required"),
          otherwise: () => Yup.mixed().nullable(),
        }),
        preview: Yup.string().nullable(),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      rows: [
        {
          document: "Photograph (passport size photo required png or jpg )",
          key: `pic`,
          name: "",
          type: "image/png, image/jpeg",
          file: null,
          preview: null,
        },
        {
          document: "PAN",
          key: "doc_pan",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: `Aadhaar`,
          key: "doc_aadhaar",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "GST Registration",
          key: "gst_registration",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "Proof of Office Address",
          key: "proof_office_address",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "Articles of Incorporation",
          key: "articles_incorporation",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "Pitch Deck",
          key: "pitch_deck",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "DPIIT Recognised Certificate",
          key: "dpiit_recognised_certificate",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "Any other Document",
          key: "any_other_document",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        values.rows.forEach((row, index) => {
          if (row.file) {
            formData.append(row.key, row.file);
          }
        });
        formData.append("application_id", _id);
        let data = {
          step: "document",
          payload: formData,
        };
        const response = await newApplicant(data);
        if (response?.data?.success) {
          toast.success("Document Uploaded Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          localStorage.removeItem("application_id");
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
            navigate("/application-status");
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleNext = () => {
    formik.handleSubmit();
  };

  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };

  useEffect(() => {
    const UpdateApplication = async () => {
      if (_id) {
        try {
          const data = {
            companyName: "Company Name",
            projectFormation: "Project Formation",
            address: "Address",
            incubation_centre: "Incubation Centre",
          };
          formik.setValues((prevValues) => ({
            ...prevValues,
            ...data,
          }));
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateApplication();
  }, [_id]);

  const fileInputRef = useRef([]);

  const handleIconClick = (index) => {
    fileInputRef.current[index].click();
  };

  const handleFileUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const newRows = [...formik.values.rows];
      newRows[index].file = file;
      newRows[index].name = file.name;
      newRows[index].preview = URL.createObjectURL(file);
      formik.setFieldValue("rows", newRows);
    }
  };
  useEffect(() => {
    const UpdateApplication = async () => {
      if (_id) {
        try {
          const { data } = await getAllApplicant(_id);
          if (data?.application) {
            const updatedRows = formik.values.rows.map((row) => {
              return {
                ...row,
                // name: data.application[row.key] || "",
                file: data.application[row.key] || "",
                preview: data.application[row.key] || null,
              };
            });
            formik.setValues({ rows: updatedRows });
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [_id, getAllApplicant]);

  const hasPreview = formik.values.rows.some((row) => row.preview);
  const hasName = formik.values.rows.some((row) => row.name);

  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align=""
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Document Uploaded
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          <Grid item md={12} lg={12} xl={12} xs={12}>
            <Typography
              variant="h6"
              align=""
              sx={{ fontWeight: 500, fontSize: "18px" }}
            >
              Documents:
            </Typography>
            <TableContainer component={Paper} fullWidth>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Document</TableCell>
                    <TableCell>File</TableCell>
                    {hasName ? <TableCell>Name</TableCell> : null}
                    {hasPreview ? <TableCell>Preview</TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formik.values.rows?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.document}</TableCell>
                      <TableCell>
                        <Button
                          title="Upload Document"
                          variant="contained"
                          size="small"
                          color="info"
                        >
                          <CloudUploadIcon
                            style={{ cursor: "pointer", color: "#fff" }}
                            onClick={() => handleIconClick(index)}
                          />
                          {formik.errors?.rows?.[index]?.file &&
                            formik.touched?.rows?.[index]?.file && (
                              <Typography
                                color="error"
                                variant="caption"
                                mt={1}
                              >
                                {formik.errors.rows[index].file}
                              </Typography>
                            )}

                          <input
                            type="file"
                            accept={row.type}
                            style={{ display: "none" }}
                            ref={(el) => (fileInputRef.current[index] = el)}
                            onChange={(event) => handleFileUpload(index, event)}
                          />
                        </Button>
                      </TableCell>

                      <TableCell>
                        {row.name}
                        {hasName &&
                          formik.errors?.rows?.[index]?.name &&
                          formik.touched?.rows?.[index]?.name && (
                            <Typography color="error" variant="caption">
                              {formik.errors.rows[index].name}
                            </Typography>
                          )}
                      </TableCell>

                      <TableCell>
                        {row.preview && (
                          <Link
                            to={row.preview}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                           <Button
                              title="View Document"
                              variant="contained"
                              size="small"
                            >
                              <VisibilityIcon color="inherit" />
                            </Button>
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <Button
                type="button"
                variant="contained"
                onClick={() => navigate(`/applicant/preview/${_id}`)}
                disabled={active === 0}
              >
                Preview Application
              </Button>
            </Box>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={active === steps.length}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
              >
                <span>Final Submit</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DocumentUploaded;
